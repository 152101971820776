import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import background from '../Img/Landing/moneyshot.jpg';
import logoEmblem from '../Img/Landing/hundredweight-logo-emblem.png';
import cocktailText from '../Img/Landing/Forasuperiorcocktail.png';

const BackgroundImage = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100vh', // Full viewport height
  display: 'flex',
  flexDirection: 'column', // Stacks children vertically
  justifyContent: 'center', // Align items from the start to manage center-right alignment
  alignItems: 'center', // Push items to the right edge
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    height: '65vh', // Full viewport height
    alignItems: 'center', // Center items on smaller screens
  },
  [theme.breakpoints.down('lg')]: {
    height: '70vh', // Full viewport height
    alignItems: 'center', // Center items on smaller screens
  },
}));

const LogoEmblem = styled('img')(({ theme }) => ({
  height: '100%',
  transition: 'width 0.2s ease-in-out',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },

}));

const CocktailText = styled('img')(({ theme }) => ({
  height: '100%',
  aspectRatio: 2/1,
  marginBottom: '2vh',
  transition: 'width 0.2s ease-in-out',
  [theme.breakpoints.down('md')]: {
    maxWidth: '90%',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#fff',
  
  fontSize: '1.5rem',
  lineHeight: '1',
  fontWeight: 600,
  fontFamily: 'Open Sans Regular',
  textTransform: 'uppercase',
  maxWidth: '50%',
  [theme.breakpoints.down('md')]: {
    fontSize: '.75rem',
    maxWidth: '95%',
  },
}));

const StyledTypography2 = styled(Typography)(({ theme }) => ({
  color: '#fff',
  
  fontSize: '1rem',
  lineHeight: '1.5',
  fontWeight: 400,
  margin: '20px 0',
  textTransform: 'uppercase',
  maxWidth: '50%',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.5rem',
    maxWidth: '95%',
  },
}));

function LandingSection() {
  return (
    <Container disableGutters maxWidth="100vw" sx={{ margin: 0, padding: 0, width: "100%", height: "100%" }} >
      <BackgroundImage>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={5}>
            {/* Left 50% Empty Space */}
          </Grid>
          <Grid item xs={7} container >
            <Grid container  direction="column" sx={{ height: '100%' }}>
              <Grid item xs={3} container justifyContent="center">
                <LogoEmblem src={logoEmblem} alt="HundredWeight Big Ice Emblem" />
              </Grid>
              <Grid item xs={3} container justifyContent="center">
                <CocktailText src={cocktailText} alt="For a Superior Cocktail" />
              </Grid>
              <Grid item xs={3} container justifyContent="center" alignContent="center" direction="column">
                <StyledTypography variant="body1" sx={{ textAlign: 'center',}}>
                  The World's Premier Crystal-clear cocktail ice production and delivery company.
                </StyledTypography>
                <StyledTypography2 variant="body1" sx={{ textAlign: 'center',}}>
                  Serving New York City and Beyond since 2011
                </StyledTypography2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BackgroundImage>
    </Container>
  );
}


export default LandingSection;
