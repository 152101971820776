import React, { useState } from 'react';
import { Box, Grid, Card, CardMedia, Container, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import header from '../Img/Gallery/Gallery_header.png';
import leftArrow from '../Img/Gallery/left arrow.png';
import rightArrow from '../Img/Gallery/right_arrow.png';
import image1 from '../Img/Gallery/1.png';
import image2 from '../Img/Gallery/2.png';
import image3 from '../Img/Gallery/3.png';
import image4 from '../Img/Gallery/4.png';
import image5 from '../Img/Gallery/5.png';
import image6 from '../Img/Gallery/6.png';
import image7 from '../Img/Gallery/7.png';

const galleryItems = [
  {
    title: 'HAWKSMOOR',
    subtitle: 'Huw Gott',
    description: 'BUY THE BEST AND LET IT SHINE',
    image: image1,
  },
  {
    title: 'BAR GOTO',
    subtitle: 'Matthew Reyes',
    description: 'BECAUSE HUNDREDWEIGHT ICE MELTS MORE SLOWLY...',
    image: image2,
  },
  {
    title: 'ATTABOY',
    subtitle: 'Sam Ross',
    description: 'SHE’S AS COLD AS ICE, WILLING TO SACRIFICE',
    image: image3,
  },
  {
    title: 'ATTABOY',
    subtitle: 'Sam Ross',
    description: 'SHE’S AS COLD AS ICE, WILLING TO SACRIFICE',
    image: image4,
  },
  {
    title: 'ATTABOY',
    subtitle: 'Sam Ross',
    description: 'SHE’S AS COLD AS ICE, WILLING TO SACRIFICE',
    image: image5,
  },
  {
    title: 'ATTABOY',
    subtitle: 'Sam Ross',
    description: 'SHE’S AS COLD AS ICE, WILLING TO SACRIFICE',
    image: image6,
  },
  {
    title: 'ATTABOY',
    subtitle: 'Sam Ross',
    description: 'SHE’S AS COLD AS ICE, WILLING TO SACRIFICE',
    image: image7,
  },
  // Add more items as needed
];

const Header = styled('img')(({ theme }) => ({
  height: 'auto',
  maxHeight: "50%",
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    maxWidth: '80%',
  },
}));

const ProductContainer = styled(Box)(({ theme }) => ({
  minHeight: '80vh',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    minHeight: '50vh',
  },
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
}));

const Gallery = () => {
  const [startIndex, setStartIndex] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const itemsPerPage = isMobile ? 1 : 3;

  const handlePrevClick = () => {
    setStartIndex((prevIndex) => 
      (prevIndex - 1 + galleryItems.length) % galleryItems.length
    );
  };

  const handleNextClick = () => {
    setStartIndex((prevIndex) => 
      (prevIndex + 1) % galleryItems.length
    );
  };

  const displayedItems = [];
  for (let i = 0; i < itemsPerPage; i++) {
    displayedItems.push(galleryItems[(startIndex + i) % galleryItems.length]);
  }
  return (
    <Container disableGutters maxWidth="100vw" sx={{ margin: 0, padding: 0, width: '100%' }}>
      <ProductContainer id="gallery">
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          <Header src={header} alt="Our Products Header" />
        </Grid>
        <Grid container alignItems="center" justifyContent="center" spacing={0} sx={{ width: '100%', padding: 0, position: 'relative' }}>
          <ArrowButton sx={{ left: 10 }} onClick={handlePrevClick}>
            <img src={leftArrow} alt="Previous" style={{ width: '30px', height: '30px' }} />
          </ArrowButton>
          {displayedItems.map((item, index) => (
            <Grid item key={index} xs={12} md={4} sx={{ padding: 1, backgroundColor: 'transparent' }}>
              <Card sx={{ height: '100%', padding: 0 , borderRadius: 0, transition: 0, boxShadow: 0, backgroundColor: 'transparent'}}>
                <CardMedia
                  component="img"
                  image={item.image}
                  alt={item.title}
                  sx={{ height: "100%", objectFit: 'cover', backgroundColor: 'transparent' }} // Ensure full scale image within the card height
                />
              </Card>
            </Grid>
          ))}
          <ArrowButton sx={{ right: 10 }} onClick={handleNextClick}>
            <img src={rightArrow} alt="Next" style={{ width: '30px', height: '30px' }} />
          </ArrowButton>
        </Grid>
      </ProductContainer>
    </Container>
  );
};

export default Gallery;