import React, { useState } from 'react';
import { Box, Grid, Container, Typography, TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import header from '../Img/Contact/Contact_header.png';
import vanImage from '../Img/Contact/Truck.png';
import stayCool from '../Img/Contact/StayCool.png';
import instagram from '../Img/Contact/instahram.png';
import facebook from '../Img/Contact/facebook.png';

const Header = styled('img')(({ theme }) => ({
  height: 'auto',
  maxHeight: "50%",
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    maxWidth: '80%',
  },
}));

const ContactContainer = styled(Box)(({ theme }) => ({
  minHeight: '70vh',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    minHeight: '50vh',
  },
}));

const SocialMediaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: "100%",
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  '& img': {
    margin: theme.spacing(1),
    cursor: 'pointer',
    width: '40px',
    height: 'auto',
  },
}));

// Styled TextField with consistent border color
const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[500], // Default border color
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[700], // Hover border color
      color: theme.palette.grey[700], // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.grey[500], // Focused border color (same as default)
      color: theme.palette.grey[700], // Hover border color
    },
  },
  '& .MuiInputLabel-root': {
      borderColor: theme.palette.grey[500], // Focused border color (same as default)
      color: theme.palette.grey[700], // Hover border color
  }
}));

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Form Data:', formData); // Log form data for debugging
      const response = await fetch('https://thewhiskeycompanion.com/login/api/common/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData),
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message || 'Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      } else {
        alert(result.message || 'Error sending message. Please try again.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Error sending message. Please try again.');
    }
  };

  return (
    <Container disableGutters maxWidth="100vw" sx={{ margin: 0, padding: 0, width: '100%', height: "100%" }}>
      <ContactContainer id="contact">
        <Grid container  direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%', height: "100%" }}>
          <Header src={header} alt="Contact Header" />
        </Grid>
        <Grid container  spacing={2} alignItems="center" justifyContent="center" sx={{ width: '100%', padding: theme => theme.spacing(4), position: 'relative' }}>
          <Grid item xs={12} md={7} sx={{ textAlign: 'center' }}>
            <img src={vanImage} alt="Van" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} md={5} sx={{ textAlign: 'left' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans Extra Bold', }} gutterBottom>
              DO YOU WANT A SUPERIOR CALIBER OF ICE FOR THE COCKTAILS AT YOUR BAR, RESTAURANT, OR SPECIAL EVENT?
            </Typography>
            <br />
            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans Extra Bold' }} gutterBottom>
              LOOK NO FURTHER!
            </Typography>
            <br />

            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans Extra Bold' }} gutterBottom>
              HUNDREDWEIGHT ICE WILL SURELY IMPRESS YOUR GUESTS. INQUIRE ABOUT CUSTOM CUTS TO FIT YOUR GLASSWARE!
            </Typography>
            <br />

            <Typography variant="h5" sx={{ fontStyle: 'italic' }} gutterBottom>
              Contact us by phone at: <br />
              <Typography variant="h3" component="span" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans Extra Bold' }}>
                (347) 764-5205
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Typography variant="body1" gutterBottom>
              DELIVERY HOURS: 
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans Extra Bold' }}>
               <> </> Monday through Saturday 11AM until 8PM<br />
              </Typography>
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: 'italic',fontWeight: 'bold', fontFamily: 'Open Sans Italic' }} gutterBottom>
              *please provide at least 48 hours' notice for event orders
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: '100%', padding: theme => theme.spacing(2), position: 'relative' }}>
          <Grid item xs={12} md={12}>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                sx={{ backgroundColor: "white" }}
                label="Name"
                variant="outlined"
                margin="normal"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                sx={{ backgroundColor: "white" }}
                label="Email"
                variant="outlined"
                margin="normal"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                sx={{ backgroundColor: "white" }}
                label="Subject"
                variant="outlined"
                margin="normal"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                sx={{ backgroundColor: "white" }}
                label="Message"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <img src={stayCool} alt="Submit" style={{ cursor: 'pointer', width: '200px', height: 'auto', marginTop: '16px' }} onClick={handleSubmit} />
              </Grid>
            </form>
          </Grid>

          <SocialMediaContainer>
            <a href="https://www.instagram.com/hundredweightice" target="_blank" rel="noopener noreferrer">
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100064205073169" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" />
            </a>
          </SocialMediaContainer>
          <Typography variant="body2" align="center" sx={{ marginTop: '8px', fontStyle: 'italic' }}>
            <br />© HUNDREDWEIGHT.ICE
          </Typography>
        </Grid>
      </ContactContainer>
    </Container>
  );
};

export default Contact;
