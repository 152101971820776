import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

import ProductsHeader from './components/Products/ProductsHeader';
import MiniTallCubes from './components/Products/MiniTallCubes';
import TwoInchCubes from './components/Products/TwoInchCubes';
import HighBallSpears from './components/Products/HighBallSpears';
import Spheres from './components/Products/Spheres';
import EmbeddedIce from './components/Products/EmbeddedIce';
import EngravedIce from './components/Products/EngravedIce';
import Diamonds from './components/Products/Diamonds';
import BlocksAndSlabs from './components/Products/BlocksAndSlabs';
import FiveByTens from './components/Products/FiveByTens';
import TenInchBlocks from './components/Products/TenInchBlocks';
import StirringIce from './components/Products/StirringIce';
import CrushedIce from './components/Products/CrushedIce';


import OurTeam from './components/OurTeam';
import OurClients from './components/OurClients';
import ClientsSection from './components/ClientsSection';
import ContactInfo from './components/ContactInfo';
import Footer from './components/Footer';
import LandingSection from './components/LandingSection'; // Import the new component
import { ThemeProvider, createTheme } from '@mui/material/styles';
import iceBackground from './Img/Our_Products/Ice.png';
import Gallery from './components/Gallery';
import Press from './components/Press';
import Contact from './components/Contact';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#0d47a1',
    },
    background: {
      default: '#f0f0f0',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans Regular"',
    ].join(','),
    h5: {
      fontWeight: 550,
      fontSize: 24,
    },
    body1: {
      fontWeight: 350,
      fontSize: 20,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
        }
      }
    }
  }
});

const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${iceBackground})`,
  backgroundSize: 'cover',  // Adjust if you want the image to cover more space per tile
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',  // Ensure it covers the full width
  minHeight: '100vh',  // Minimum height covers full viewport height
  height: "auto",
  flexGrow: 1,  // Allows the container to grow with the content
}));


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-ZLL7522NZM');
          `}
        </script>
      </Helmet>
      <Container disableGutters maxWidth="100vw" sx={{margin:0,padding:0,width: "100%",height:"auto",overflow: 'hidden' }} >
        <BackgroundContainer>
          <Header />
          <LandingSection />
          <ProductsHeader/>
          <TwoInchCubes/>
          <MiniTallCubes/>
          <Spheres/>
          <HighBallSpears/>
          <EmbeddedIce/>
          <EngravedIce/>
          <Diamonds/>
          <BlocksAndSlabs/>
          <FiveByTens/>
          <TenInchBlocks/>
          <StirringIce/>
          <CrushedIce/>
          <OurTeam/>
          <OurClients/>
          <Gallery />
          <Press />
          <Contact />
        </BackgroundContainer>
        

        {/* <ClientsSection clients={[{ logo: 'client1.png', name: 'Client 1' }]} />
        <ContactInfo />
        <Footer /> */}
      </Container>
    </ThemeProvider>
  );
}

export default App;
