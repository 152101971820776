import React, { useState } from 'react';
import { Box, Typography, Grid,Container } from '@mui/material';
import { styled } from '@mui/material/styles';

import productsBackground from '../../Img/Our_Products/Ice.png';
import header from '../../Img/Our_Products/our_products_header.png';

const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center', // centers the background image
  height: '40vh', // Set the height as needed
  width: "100%",
  display: 'flex',
  alignItems: 'center', // vertically centers the child content (text)
  justifyContent: 'center', // horizontally centers the child content (text)
  position: 'relative', // Set position as relative for absolute positioning of children
  [theme.breakpoints.down('md')]: {
    height: '20vh',
  },
}));

const Header = styled('img')(({ theme }) => ({
  height: '50%',
  position: 'absolute', // Set position as relative for absolute positioning of children
  top: "0%",
  paddingBottom: "5%",
  aspectRatio: 4/1,
  [theme.breakpoints.down('md')]: {
    height: '50%',
    maxWidth: "80%"
  },
}));

const ProductContainer = styled(Box)(({ theme }) => ({
  height: "40vh",
  alignItems: 'center', // vertically centers the child content (text)
  justifyContent: 'center', // horizontally centers the child content (text)
  [theme.breakpoints.down('md')]: {
    height: '20vh',
  },
}));

const StyledHeaderText = styled(Typography)(({ theme }) => ({
  color: '#000',
  
  fontSize: '2rem',
  lineHeight: '1',
  fontWeight: 600,
  margin: '20px 0',
  textTransform: 'uppercase',
  paddingTop: "10%",
  maxWidth: '45%',
  [theme.breakpoints.down('md')]: {
    fontSize: '.8rem',
    height: "1%",
    maxWidth: '65%',
  },
}));
const ProductsHeader = () => {

  return (
    <Container disableGutters maxWidth="100vw" sx={{margin:0,padding:"2%",width: "100%",height:"100%" }}>

    <ProductContainer id="our-products">
      {/* Our-products header */}
      <BackgroundContainer>
        <Header src={header} alt="Our Products Header" />
        <StyledHeaderText variant="body1" sx={{ paddingTop:"10%", maxWidth: '55%', textAlign: 'center'}}>
            Enjoy your beverages with only the finest caliber of frozen water!
        </StyledHeaderText >
      </BackgroundContainer>
           
    </ProductContainer>
    </Container>
  );
};

export default ProductsHeader;
