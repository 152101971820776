import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box, useTheme, useMediaQuery, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { styled } from '@mui/material/styles';
import logo from '../Img/Nav/logo.png'; // Adjust path as necessary
import rolloverMountain from '../Img/Nav/rollover_mountain.png'; // Adjust path as necessary

const NavButton = styled(Button)({
  position: 'relative',
  color: 'black',
  fontWeight: 'bold',
  fontSize: '1rem',
  textTransform: 'none',
  whiteSpace: 'nowrap',  // Prevents text from wrapping to a new line
  '&:hover': {
    color: 'grey',
  },
  '&:hover img': {
    opacity: 1
  },
});

const HoverImage = styled('img')({
  position: 'absolute',
  alignContent: 'center',
  width: '25px',
  aspectRatio: 1.25/1,
  transform: 'translateY(-80%)',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  pointerEvents: 'none' // Prevents the image from interfering with button clicks
});

const DividerBar = styled('div')({
  height: '20px', // Adjust height according to your nav height
  width: '2px',
  backgroundColor: '#000', // Adjust the color to match your theme
  margin: '0 12px', // Adjust spacing around the divider
});
const handleScrollToButton = (text) => {
  const formattedText = text.replace(/\s+/g, '-').toLowerCase();
  document.getElementById(formattedText).scrollIntoView({ behavior: 'smooth' });
};

const Header = () => {
  
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ width: 250 }}>
      <List>
        {['OUR PRODUCTS', 'OUR STORY', 'GALLERY', 'PRESS', 'CONTACT', 'ORDERS & DELIVERY'].map((text) => (
          <ListItem button key={text}>
            <NavButton onClick={()=>handleScrollToButton(text)}>
              <ListItemText primary={text} />
            </NavButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Container disableGutters maxWidth="100vw" sx={{margin:0,padding:0,width: "100%",height:"100%" }}  >
      <AppBar position="static" color="primary" sx={{ zIndex:1000, alignContent: 'flex-start', alignItems: 'flex-start', alignSelf: 'flex-start', justifySelf: 'flex-start', width: "100%", padding: '10px 20px' }}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: isMobile ? 'block' : 'none' }}
        >
          <MenuIcon />
        </IconButton>
          <img src={logo} alt="Logo" style={{ paddingLeft:'15%', height: '50px' }} />
          {!isMobile && (
            <Box sx={{ zIndex:1000,display: 'flex', alignItems: 'center', alignContent: 'flex-end', alignSelf: 'flex-end', justifySelf: 'flex-end', paddingLeft:"5%" }}>
              {['OUR PRODUCTS', 'OUR STORY', 'GALLERY', 'PRESS', 'CONTACT', 'ORDERS & DELIVERY'].map((text, index, array) => (
                <React.Fragment key={text}>
                  <NavButton onClick={()=>handleScrollToButton(text)}>
                    <HoverImage src={rolloverMountain} alt="" />
                    {text}
                  </NavButton>
                  {index !== array.length - 1 && <DividerBar />} 
                </React.Fragment>
              ))}
            </Box>
         )}

        </Toolbar>
        <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
      </AppBar>
    </Container>
    
  );
};

export default Header;
